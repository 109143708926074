<template>
  <div>
    <el-table
      :data="rows"
      class="table-responsive align-items-center table-flush"
    >
      <el-table-column
        :label="$t('COMMON.PAYMENT_METHOD')"
        prop="name"
        align="center"
      >
        <template v-slot="{ row }">
          <base-input
            :value="$t(`CASH_REGISTER_READINGS.${row.name.toUpperCase()}`)"
            input-classes="form-control-alternative"
            :readonly="true"
          />
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('COMMON.AMOUNT')"
        prop="amount"
        align="center"
      >
        <template v-slot="{ row }">
          <base-input
            :disable="loading"
            v-model="row.amount"
            input-classes="form-control-alternative"
            type="number"
            @change="updatePaymentItemDebounced(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import _ from "lodash";
import { cloneDeep } from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import TaxGroupsSelector from "../TaxGroupsSelector.vue";
import CategoryGroupSelector from "@/components/CategoryGroupSelector.vue";

export default {
  name: "cash-register-reading-payment-items",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    HtmlEditor,
    TaxGroupsSelector,
    CategoryGroupSelector,
  },

  mixins: [requestErrorMixin],

  props: {
    cashRegisterReading: {
      type: Object,
      default: null,
      description: "cash register reading",
    },

    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    const defaultCashRegisterReadingPaymentItem = {
      type: "cash-register-reading-payment-items",
      card: 0,
      cash: 0,
      prepaied_card: 0,
      relationshipNames: ["cashRegisterReading"],
      cashRegisterReading: {
        type: "cash-register-readings",
        id: null,
      },
      allowedUsers: [],
    };

    let cashRegisterReadingPaymentItem = cloneDeep(
      defaultCashRegisterReadingPaymentItem
    );

    if (
      this.cashRegisterReading &&
      this.cashRegisterReading.cashRegisterReadingPaymentItem
    ) {
      cashRegisterReadingPaymentItem = cloneDeep(
        this.cashRegisterReading.cashRegisterReadingPaymentItem
      );
    } else if (this.cashRegisterReading) {
      cashRegisterReadingPaymentItem.cashRegisterReading.id =
        this.cashRegisterReading.id;
    }
    return {
      query: "",
      selectedRows: [],
      sort: "created_at",
      rows: [
        { name: "cash", amount: 0 },
        { name: "credit_card", amount: 0 },
        { name: "debit_card", amount: 0 },
        { name: "prepaied_card", amount: 0 },
        { name: "other", amount: 0 },
      ],
      cashRegisterReadingPaymentItem,
      loading: true,
    };
  },

  computed: {
    totalAmount() {
      return this.rows.reduce((sum, row) => sum + Number(row.amount), 0);
    },
  },

  watch: {
    cashRegisterReading: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.cashRegisterReadingPaymentItem) {
          this.rows[0].amount =
            newValue.cashRegisterReadingPaymentItem.cash || 0;
          this.rows[1].amount =
            newValue.cashRegisterReadingPaymentItem.credit_card || 0;
          this.rows[2].amount =
            newValue.cashRegisterReadingPaymentItem.debit_card || 0;
          this.rows[3].amount =
            newValue.cashRegisterReadingPaymentItem.prepaied_card || 0;
          this.rows[4].amount =
            newValue.cashRegisterReadingPaymentItem.other || 0;
        }
      },
    },
  },

  methods: {
    updatePaymentItemDebounced: _.debounce(function (row) {
      this.updatePaymentItem(row);
    }, 300),

    async updatePaymentItem(row) {
      this.cashRegisterReadingPaymentItem[row.name] = Number(row.amount);
      this.loading = true;
      try {
        const cashRegisterReadingPaymentItemData = cloneDeep(
          this.cashRegisterReadingPaymentItem
        );
        if (cashRegisterReadingPaymentItemData.id) {
          await this.$store.dispatch(
            "cashRegisterReadingPaymentItems/update",
            cashRegisterReadingPaymentItemData
          );
        } else {
          delete cashRegisterReadingPaymentItemData.id;
          await this.$store.dispatch(
            "cashRegisterReadingPaymentItems/add",
            cashRegisterReadingPaymentItemData
          );
        }

        this.$notify({
          type: "success",
          message: this.$t(
            "CASH_REGISTER_READINGS.CASH_REGISTER_READING_UPDATED"
          ),
        });
        this.$emit("cashRegisterReadingUpdated");
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }

      this.$emit("onExpenseChanged");
    },
    expenseUpdated() {
      this.$emit("cashRegisterReadingUpdated");
    },
  },
};
</script>

<style>
.supplier-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
</style>
