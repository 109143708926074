<template>
  <div>
    <div>
      <h3>
        {{ $t("CASH_REGISTER_READINGS.TOTAL_AMOUNT") }}:
        {{ this.$formatCurrency(totalAmount) }}
      </h3>
      <el-table
        class="table-responsive align-items-center table-flush supplier-product-list"
        header-row-class-name="thead-light"
        :data="invoices"
        type="expand"
        row-key="id"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column type="expand">
          <template slot-scope="props">
            <form
              class=""
              @submit.prevent=""
              v-if="invoicesModel[props.row.id]"
            >
              <base-input
                :label="$t('COMMON.EXCERPT')"
                :placeholder="$t('COMMON.EXCERPT')"
                input-classes="form-control-alternative"
              >
                <html-editor
                  v-model="invoicesModel[props.row.id].excerpt"
                  @change="updateProductItemDebounced(props.row.id)"
                >
                </html-editor>
              </base-input>
            </form>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="props">
            <form
              class=""
              @submit.prevent=""
              v-if="invoicesModel[props.row.id]"
            ></form>
          </template>
        </el-table-column>

        <el-table-column
        label="NUMERO FACTURE"
          prop="code"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input
              placeholder="NUMERO FACTURE"
              v-model="invoicesModel[row.id].code"
              input-classes="form-control-alternative"
              @change="updateProductItemDebounced(row.id)"
            >
            </base-input>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('COMMON.AMOUNT')"
          prop="amount"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input
              :placeholder="$t('COMMON.AMOUNT')"
              v-model="invoicesModel[row.id].amount"
              type="number"
              input-classes="form-control-alternative"
              @change="updateProductItemDebounced(row.id)"
            >
            </base-input>
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="120px">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Loading"
              placement="top"
              v-if="invoicesModel[row.id].loading"
            >
              <a
                type="text"
                class="table-action table-action-delete"
                data-toggle="tooltip"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              placement="top"
              v-if="
                $currentUserCan($permissions.PERM_EDIT_CASH_REGISTER_READINGS)
              "
            >
              <a
                type="text"
                @click="deleteProductItem(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div class="row px-3 mt-3">
        <div v-if="loading" class="col-12 text-center">
          <i class="fas fa-spinner fa-spin fa-2x"></i>
        </div>
        <base-button
          v-if="!loading"
          type="info"
          class="btn-sm mr-1 col-12"
          style="width: 100%"
          @click="addLine"
        >
          <i class="fas fa-plus"></i>
          {{ addLineText }}
        </base-button>
      </div>
    </div>

  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import TaxGroupsSelector from "../TaxGroupsSelector.vue";
import CategoryGroupSelector from "@/components/CategoryGroupSelector.vue";

export default {
  name: "cash-register-reading-invoice-items",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    HtmlEditor,
    TaxGroupsSelector,
    CategoryGroupSelector,
  },

  mixins: [requestErrorMixin],

  props: {
    addLineText: {
      type: String,
      description: "Text for add line",
    },
    product: {
      type: Object,
      default: null,
      description: "Product",
    },
    cashRegisterReading: {
      type: String,
      default: null,
      description: "cash register reading",
    },
    destinationWarehouseId: {
      type: String,
      default: null,
      description: "warehouse id",
    },

    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      query: "",
      selectedRows: [],
      sort: "created_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      invoices: [],
      loading: true,
      selectedCategory: null,
      invoicesModel: {},
    };
  },

  computed: {
    totalAmount() {
      return this.invoices.reduce((total, product) => {
        return total + (parseFloat(product.amount) || 0);
      }, 0);
    },
   
  },

  watch: {
    pagination: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
    invoices: {
      handler: "refreshinvoices",
      immediate: true,
    },
    query: {
      handler: "getListDebounced",
      immediate: false,
    },

    selectedCategory: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    updateProductItemDebounced: _.debounce(function (id) {
      this.updateProductItems(id);
    }, 300),

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "cashRegisterReading",
        };

        if (!!this.cashRegisterReading) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              cashRegisterReading: this.cashRegisterReading,
            },
          };
        }

        if (this.selectedCategory) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              productInCategory: this.selectedCategory,
            },
          };
        }
        await this.$store.dispatch(
          "cashRegisterReadingInvoiceItems/list",
          params
        );
        this.invoices =
          this.$store.getters["cashRegisterReadingInvoiceItems/list"];
        this.total =
          this.$store.getters["cashRegisterReadingInvoiceItems/listTotal"];
        this.loading = false;
       
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    refreshinvoices() {
      const invoicesModelData = {};
      for (const item of this.invoices) {
        invoicesModelData[item.id] = _.cloneDeep(item);
        invoicesModelData[item.id].loading = false;
      }
      this.invoicesModel = invoicesModelData;
    },

    addLine() {
      let newLinesIds = Object.values(this.invoices)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;
      const newCashRegisterReadingInvoiceItem = {
        type: "cash-register-reading-invoice-items",
        id: newLineId,
        amount: null,
        excerpt: null,
        code: null,
        relationshipNames: ["cashRegisterReading"],
        cashRegisterReading: {
          type: "cash-register-readings",
          id: this.cashRegisterReading,
        },

        loading: false,
      };
      this.invoices.push(_.cloneDeep(newCashRegisterReadingInvoiceItem));
      this.invoicesModel[newLineId] = _.cloneDeep(
        newCashRegisterReadingInvoiceItem
      );
    },

    async deleteProductItem(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("PRODUCTS.DELETE_THIS_SUPPLIER_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
        await this.$store.dispatch(
          "cashRegisterReadingInvoiceItems/destroy",
          row.id
        );
      }
      this.getList();
    },

    async updateProductItems(id) {
      this.invoicesModel[id].loading = true;
      const productData = this.invoicesModel[id];

      if (productData.id > 0) {
        await this.$store.dispatch(
          "cashRegisterReadingInvoiceItems/update",
          productData
        );
      } else {
        if (this.cashRegisterReading) {
          delete productData.id;
          await this.$store.dispatch(
            "cashRegisterReadingInvoiceItems/add",
            productData
          );
        }
      }
      this.getList();
      this.$emit("onInvoiceChanged");
    },
  },
};
</script>

<style>
.supplier-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
</style>
